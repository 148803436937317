.thank-you-container {
  text-align: center;
  padding: 40px;
  color: #EBF2FD;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../../assets/home-banner/thank-you.png");
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
  background-size: cover;
  position: relative;
  z-index: 9;
}

.thank-you-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.562);
  /* Adjust the alpha value (last parameter) to change darkness */
}

.thank-you-heading {
  color: #FFB300;
  font-size: 4em;
  margin-bottom: 20px;
}

.thank-you-message {
  font-size: 1.5em;
  line-height: 1.5;
  margin-bottom: 40px;
}

.to-home {
  text-decoration: none;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition for color change */
}




@media (max-width: 600px) {
  .thank-you-container {
    height: 70vh;
  }

  .thank-you-heading {
    font-size: 2em;
  }

  .thank-you-message {
    font-size: 1em;
  }
}