.services-banner {
    background: linear-gradient(rgba(16, 15, 15, 0.42), rgba(16, 15, 15, 0.42)), url(../../assets/services-banner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.services-section {
    margin: 60px 150px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.services-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}


.service-card-row-reverse {
    flex-direction: row-reverse;
}


.services-card-title {
    color: #0B4066;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px solid #E7ECF0;
    margin: 0;
    padding-bottom: 1rem;
}

.services-card-short-title {
    color: #FFB300;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.5rem 0;
}

.services-card-desc {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.services-card-desc ul li {
    margin-bottom: 15px;
}

.services-card-desc ul li::marker {
    color: #FFB300;
}

.services-card-desc ul {
    padding-left: 18px;
}

.services-card-desc p {
    margin: 1.5rem 0;
    line-height: 28px;
}

.services-card-img {
    width: 47%;
}

.services-card-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.services-card-content {
    width: 47%;
}

.services-card-content a {
    text-decoration: none;
    color: #FFB300;
}



@media (min-width:1200px) and (max-width:1400px) {
    .services-section {
        margin: 60px 100px;
        margin-bottom: 20px;

    }
}

@media (min-width:992px) and (max-width:1199px) {
    .services-section {
        margin: 60px 50px;
        margin-bottom: 20px;

    }

}


@media screen and (max-width:991px) {
    .services-section {
        margin: 30px 20px;
        margin-bottom: 5px;
    }
}

@media screen and (max-width:767px) {
    .service-card-row-reverse {
        flex-direction: column;
    }

    .services-card-img {
        width: 100%;
    }

    .services-card-content {
        width: 100%;
    }

    .services-card {
        flex-direction: column;
        margin-bottom: 50px;
    }

    .services-card-title {
        padding-top: 1rem;
    }

}

@media screen and (max-width:575px) {

    .services-card-title {
        font-size: 22px;
        margin-top: 0.7rem;
    }

    .services-card-short-title {
        font-size: 18px;
    }

}