body {
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
  font-display: swap;
}

html{
  scroll-behavior: auto;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}