.author-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 30px 20px;
    margin: 20px;
    margin-left: 0;
    margin-right: 0;
    background-color: #f9f9f9;
}


.author-image img {
    max-width: 100%;
    height: auto;
}

.author-details {
    flex: 1;
    margin-left: 20px;
}

.author-details h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.author-details p {
    margin-bottom: 10px;
    line-height: 1.6;
}

.author-details a {
    color: #ffb300;
    text-decoration: none;
}

.author-details a:hover {
    text-decoration: underline;
}

.social-connect {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.social-connect p {
    margin-bottom: 7px;
    color: #00000070;
}

.social-connect a {
    margin-right: 10px;
}

.social-connect img {
    width: 30px;
    height: 30px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .author-section {
        flex-direction: column;
        align-items: center;
    }

    .author-details {
        margin-left: 0;
        text-align: center;
    }

    .social-connect {
        justify-content: center;
    }
}