.description {
    color: #FFB300;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 1.8rem 0;
}















/* Media Query */

@media (min-width:992px) and (max-width:1199px) {
    .description {
        font-size: 25px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .description{
        font-size: 22px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .description{
        font-size: 22px;
    }
}

@media screen and (max-width:575px) {
    .description{
        font-size: 18px;
    }
}