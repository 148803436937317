.not-found-container {
    text-align: center;
    padding: 40px;
    background-color: #282c34b6;
    color: #ffffffc7;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../../assets/404-page2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 9;
}

.not-found-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5); 
  }

.not-found-heading {
    font-size: 5em;
    margin-bottom: 20px;
    color: #FFB300;
}

.not-found-message {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 40px;
}

.link-to-home {
    text-decoration: none;
    font-weight: bold;
    padding: 12px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}



@media (max-width: 768px) {
    .not-found-heading {
        font-size: 3.5em;
    }

    .not-found-message {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .not-found-container {
        height: 70vh;
    }

    .not-found-heading {
        font-size: 2.5em;
    }

    .not-found-message {
        font-size: 0.9em;
    }
}