.disclaimer-section{
    margin: 60px 150px;
}

                       
.canvas-container{
    display: flex;
    justify-content: center;
}


@media (min-width:1200px) and (max-width:1400px) {
    .disclaimer-section {
        margin: 60px 100px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .disclaimer-section {
        margin: 40px 50px;
    }
}

@media screen and (max-width:991px) {
    .disclaimer-section{
        margin: 30px 20px;
    }
}
@media (max-width:768px){
    .canvas-container canvas{
        width: 100%;
    }
}