.career-banner {
    background: linear-gradient(rgba(16, 15, 15, 0.42), rgba(16, 15, 15, 0.42)), url(../../assets/career-banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.career-card {
    border-radius: 6px;
    border: 1px solid #E7ECF0;
    padding: 40px 30px;
    width: 49%;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;

}

.career-card h3 {
    color: #0B4066;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.career-card h6 {
    color: #696969;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 1.4rem 0;
}

.career-card p {
    max-width: 536px;
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 1.5rem;

}

.career-card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;
}

.career-section {
    margin: 60px 150px;
}

.career-card:hover {
    color: #fff;
    background-color: #0B4066;
    transition: all 0.7s ease-in-out;
}

.career-card:hover h3,
.career-card:hover h6,
.career-card:hover p,
.career-card:hover .career-roles li {
    color: #fff;

}

.career-desc {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.career-btn {
    text-decoration: none;
}


.career-roles li {
    color: #696969;
    margin-bottom: 10px;
}

.career-roles li::marker {
    color: #FFB300;
}

.career-roles {
    margin-bottom: 2rem;
    padding-left: 17px;
    min-height: 300px;
}





















@media screen and (max-width:575px) {
    .career-card {
        width: 100%;
        padding: 30px 20px;
    }

    .career-roles {
        min-height: unset;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .career-card {
        width: 49%;
        padding: 20px;
    }

    .career-roles {
        min-height: 533px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .career-card {
        width: 49%;
        padding: 20px;
    }

    .career-roles {
        min-height: 421px;
    }
}

@media (min-width:992px) and (max-width:1400px) {
    .career-card {
        width: 49%;
    }
}

@media (min-width:1200px) and (max-width:1400px) {
    .career-section {
        margin: 60px 100px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .career-section {
        margin: 40px 50px;
    }
}

@media screen and (max-width:991px) {
    .career-section {
        margin: 30px 20px;
    }

    .career-card-container {
        margin-top: 30px;
    }
}