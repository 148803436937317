.big-title-heading {
    max-width: 777px;
    color: #0B4066;
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.92px;
    margin: 0;
    margin-bottom: 1.5rem;
    font-display: swap;
}

.big-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E7ECF0;
}

.big-title-img img {
    width: 160px;
}














/* Media Query */
@media (min-width:1200px) and (max-width:1400px) {
    .big-title-heading {
        font-size: 62px;
        max-width: 650px;
        line-height: normal;
    }
}
@media (min-width:992px) and (max-width:1199px) {
    .big-title-heading {
        font-size: 51px;
        max-width: 530px;
    }

    .big-title-img img{
        width: 125px;
    }
}


@media (min-width:768px) and (max-width:991px) {
    .big-title-heading {
        font-size: 42px;
        max-width: 372px;
    }

    .big-title-img img {
        width: 95px;
    }
}


@media (min-width:576px) and (max-width:767px) {
    .big-title-heading {
        font-size: 38px;
        max-width: 333px;
        margin-bottom: 0.7rem;
    }

    .big-title-img img {
        width: 80px;
    }
}

@media screen and (max-width:575px) {
    .big-title-heading {
        font-size: 26px;
        max-width: 100%;
        letter-spacing: 0.1px;
        margin-bottom: 0.7rem;
    }

    .big-title-img img {
        display: none;
    }
}