.ppa-banner {
    background: linear-gradient(rgba(16, 15, 15, 0.42), rgba(16, 15, 15, 0.42)), url(../../assets/ppa-banner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ppa-page-content,
.ppa-page-sec3 {
    padding: 0 150px;
    margin: 80px 0;
}

.left-pad {
    padding-left: 40px;
}

.right-pad {
    padding-right: 40px;
}



.ppa-page-sec1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.ppa-page-sec1 img {
    width: 100%;
}

.page-content-left,
.page-content-right {
    width: 50%;
}

.page-content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ppa-page-sec1-title {
    color: #0B5A97;
    margin-bottom: 1.2rem;
    position: relative;
    padding-bottom: 1.3rem;
}

.ppa-page-sec1-title::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url(../../assets/ppa/ppa-img8.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
    height: 100%;
    width: 100%;
}

.ppa-page-sec1-desc {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    max-width: 500px;
}



.ppa-page-sec2{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 50px 15px;
}

.ppa-page-sec2 h2{
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
}

.ppa-page-sec2-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.ppa-page-sec2-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 220px;
    box-sizing: border-box;
}

.ppa-page-sec2-card h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #0B5A97;
}

.ppa-page-sec2-card p {
    line-height: 28px;
}


.ppa-page-sec3 .c-container{
    display: flex;
    flex-wrap: wrap;
}

.ppa-page-sec3-content{
    width: 40%;
}

.ppa-page-sec3-content h2{
    color:#0b4066;
    margin-bottom: 1rem;
    max-width:250px;
    line-height: 38px;
}

.ppa-page-sec3-content p{
    max-width: 395px;
    line-height: 28px;
}

.ppa-page-sec3-img{
    width: 60%;
}

.ppa-page-sec3-img img{
    width: 100%;
}



/* Media Query */

@media (min-width:1200px) and (max-width:1400px) {
    .ppa-page-content,
    .ppa-page-sec3 {
        padding: 0 100px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .ppa-page-content,
    .ppa-page-sec3 {
        padding: 0 50px;
        margin: 60px 0;
    }
}

@media screen and (max-width:991px) {
    .ppa-page-content,
    .ppa-page-sec3 {
        padding: 0 20px;
        margin: 30px 0;
    }
}

@media screen and (max-width:767px) {

    .page-content-left,
    .page-content-right {
        width: 100%;
    }

    .flex-column-reverse{
        flex-direction: column-reverse;
    }
    .left-pad {
        padding-left: 0px;
    }
    
    .right-pad {
        padding-right: 0px;
    }
    .ppa-page-sec1 img {
        margin-bottom: 10px;
    }

    .ppa-page-sec2-cards {
        flex-direction: column;
        align-items: center;
    }
    .ppa-page-sec2-card {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    .ppa-page-sec3-img{
        width: 100%;
    }
    .ppa-page-sec3-content{
        width: 100%;
        margin-bottom: 10px;
    }

    .ppa-page-sec3-content h2{
        max-width:100%;
    }
    
    .ppa-page-sec3-content p{
        max-width: 100%;
    }
}