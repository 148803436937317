header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    color: #fff
}

.header-top {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    position: relative
}

.header-top-container {
    padding: 20px 150px;
    display: flex;
    justify-content: space-between
}

.header-contact {
    display: flex
}

.header-contact-item {
    margin-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #fff
}

.header-contact-item img {
    margin-right: 7px;
    width: 18px
}

.header-social-icon {
    margin-right: 7px;
    cursor: pointer
}

.header-social-icon:nth-child(4) {
    margin-right: 0
}

.header-social-icon {
    margin-right: 10px;
    height: 18px
}

.nav-logo {
    cursor: pointer
}

nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 150px;
    align-items: center;
    position: relative
}

.nav-items {
    align-items: center;
    display: flex
}

.nav-items li {
    list-style: none;
    margin-right: 20px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.023%;
    padding-bottom: 5px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    z-index: 9;
    transition: all .5s
}

.nav-items li a {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition: all .5s
}

.nav-items li a:hover {
    color: #ffb300
}

.nav-items li:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #ffca4d;
    height: 1.3px;
    transition: all .5s
}

.nav-items li:hover:after {
    width: 100%
}

.nav-items li:hover {
    color: #ffb300
}

.common-btn {
    padding: 14px 24px;
    margin: 0;
    color: #0b4066;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.023%;
    letter-spacing: .9px;
    text-transform: uppercase;
    background: linear-gradient(to right, #ffb300, #ffca4d, #ffe8b3);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-size: 300% 100%;
    text-decoration: none;
    display: inline-block;
    transition: all .4s ease-in-out
}

.common-btn:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
    animation: pulse 1s;
    box-shadow: 0 0 0 1em transparent
}

.common-btn:focus {
    outline: 0;
    animation: pulse 1s;
    box-shadow: 0 0 0 1em transparent
}

.toggle-btn {
    display: none
}

.close-btn {
    display: none
}

.sticky-nav {
    position: fixed;
    top: 0;
    padding-top: 15px;
    left: 0;
    right: 0;
    background-color: #fff;
    color: rgba(0, 0, 0, .534);
    z-index: 99;
    box-shadow: rgba(78, 82, 85, .3) 0 1px 2px 0, rgba(82, 86, 88, .15) 0 1px 3px 1px
}

.sticky-nav .nav-items li {
    color: rgb(0 0 0 / 91%)
}

.sticky-nav .nav-items li a {
    color: rgb(0 0 0 / 91%)
}

.sticky-nav .nav-items li:hover {
    color: #ffb300
}

.sticky-nav .nav-items li a:hover {
    color: #ffb300
}

.dropdown-item {
    position: relative
}

.dropdown-item::after {
    display: none
}

.dropdown-item:hover .sub-menu {
    display: block
}

.sub-menu {
    position: absolute;
    top: 25px;
    left: 0;
    background-color: #fff;
    border-top: 4px solid #ffb300;
    padding: 0;
    z-index: 9;
    display: none;
    box-shadow: rgba(0, 0, 0, .16) 0 3px 6px, rgba(0, 0, 0, .23) 0 3px 6px
}

.sub-menu li a {
    color: #131111 !important
}

.sub-menu li {
    color: #131111 !important;
    padding: 7px;
    margin-right: 0 !important;
    font-size: 15px !important;
    z-index: 9;
    width: 135px !important;
    text-transform: capitalize;
    letter-spacing: normal;
    font-weight: 400;
    border-bottom: 1px solid rgba(128, 128, 128, .171)
}

.sub-menu-2 li {
    width: 160px !important
}

.sub-menu li:focus,
.sub-menu li:hover {
    box-shadow: inset -5.7em 0 0 0 #ffca4d, inset 5.7em 0 0 0 #ffca4d
}

@keyframes subMenuAnimy {
    from {
        top: 20px;
        opacity: 0
    }

    to {
        top: 25px;
        opacity: 1
    }
}

@keyframes stickyNavAnimation {
    from {
        background-color: transparent;
        transform: translate3d(0, -50px, 0)
    }

    to {
        transform: none;
        background-color: #fff
    }
}

.c-container {
    max-width: 1920px;
    margin: 0 auto
}

.nav-btn {
    font-size: 14px;
    margin-right: 15px;
}

.partner-btn {
    color: #050F2B;
    background: 0 0;
    border: 1px solid #050F2B;
    padding-top: 12px;
    background: #FFE3A938;
    padding-bottom: 12px
}

/* .partner-btn:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
    animation: pulse 1s;
    box-shadow: 0 0 0 1em transparent
}

.partner-btn:focus {
    outline: 0;
    animation: pulse 1s;
    box-shadow: 0 0 0 1em transparent
} */

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #ffb300
    }
}

@media (min-width:1400px) and (max-width:1600px) {
    .nav-items li {
        margin-right: 12px;
        font-size: 13px;
    }
}

@media (min-width:1200px) and (max-width:1400px) {
    .header-top-container {
        padding: 20px 100px
    }

    nav {
        padding: 20px 100px
    }

    .nav-items li {
        font-size: 13px;
        margin-right: 12px
    }

    .nav-logo img {
        width: 209px
    }

    .common-btn {
        padding: 10px 15px;
        font-size: 13px
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .header-top-container {
        padding: 15px 50px
    }

    nav {
        padding: 20px 50px
    }

    .nav-items {
        display: none
    }

    .nav-logo img {
        width: 190px
    }
}

@media (min-width:576px) and (max-width:991px) {
    .header-top-container {
        padding: 10px 20px;
        font-size: 13px
    }

    nav {
        padding: 10px 20px
    }

    .nav-logo img {
        width: 176px
    }

    .nav-items {
        display: none
    }

    .nav-items li {
        font-size: 11px;
        margin-right: 10px
    }

    .common-btn {
        font-size: 11px;
        padding: 10px 12px
    }

    .toggle-btn {
        font-size: 20px
    }

    .sub-menu li:focus,
    .sub-menu li:hover {
        box-shadow: inset -33.5em 0 0 0 #ffca4d, inset 33.5em 0 0 0 #ffca4d
    }
}

@media screen and (max-width:575px) {
    .header-top-container {
        padding: 5px 20px;
        font-size: 9px
    }

    .header-contact-item {
        margin-right: 5px
    }

    .header-contact-item span {
        display: none
    }

    .header-contact-item img {
        margin-right: 5px
    }

    .header-social-icon {
        margin-right: 9px;
        height: 18px
    }

    nav {
        padding: 7px 20px
    }

    .nav-logo img {
        width: 180px
    }

    .nav-items {
        display: none
    }

    .nav-items li {
        font-size: 11px;
        margin-right: 10px
    }

    .common-btn {
        font-size: 12px;
        padding: 10px;
        letter-spacing: .5px
    }

    .toggle-btn {
        font-size: 24px
    }

    .sub-menu li:focus,
    .sub-menu li:hover {
        box-shadow: inset -19em 0 0 0 #ffca4d, inset 19em 0 0 0 #ffca4d
    }
}

@media screen and (max-width:1199px) {
    .toggle-btn {
        display: block
    }

    .nav-menu .header-top {
        display: none
    }

    .nav-menu .close-btn {
        display: block;
        color: rgba(0, 0, 0, .849)
    }

    .nav-menu .open-btn {
        display: none
    }

    .nav-menu .nav-items {
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        left: 0;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #fff;
        padding-top: 70px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: rgba(78, 82, 85, .3) 0 1px 2px 0, rgba(82, 86, 88, .15) 0 1px 3px 1px;
        overflow-y: scroll;
        overflow-x: hidden
    }

    .nav-menu .nav-items li {
        color: rgba(0, 0, 0, .849);
        margin-bottom: 15px;
        letter-spacing: 1px;
        font-size: 14px !important;
        padding: 7px;
        width: 100%
    }

    .nav-menu .nav-items li a {
        color: rgba(0, 0, 0, .849)
    }

    .nav-btn {
        font-size: 14px !important
    }

    .sub-menu {
        position: relative;
        top: 10px
    }

    .sub-menu li {
        width: 100% !important
    }

    .partner-btn {
        margin-bottom: 12px
    }
}