.about-section {
    padding: 70px 150px
}
.about-section-bottom {
    display: flex;
    justify-content: space-between
}
.about-section-bottom-padding {
    padding-top: 60px
}
.about-section-bottom-img {
    background: #ebf2fd;
    height: 366px;
    width: 478px
}
.about-section-bottom-img img {
    margin-top: 30px;
    margin-left: 30px;
    width: 100%
}
.about-section-img-container {
    width: 50%
}
.about-section-bottom-content {
    width: 50%
}
.about-section-bottom-desc {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 594px;
    margin-bottom: 1rem
}
.about-btn {
    margin-top: 1rem !important
}
.about-section-bottom-desc a {
    text-decoration: none;
    color: #ffb300
}
.about-section-bottom-desc a:hover {
    color: #ffb300a1
}

@media (min-width:1200px) and (max-width:1400px) {
    .about-section {
        padding: 60px 100px
    }
    .about-section-bottom-img {
        height: 335px;
        width: 435px
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .about-section {
        padding: 40px 50px
    }
    .about-section-bottom-img {
        height: 305px;
        width: 385px
    }
    .about-section-bottom-desc {
        font-size: 15px;
        line-height: 23px
    }

    .about-section-bottom-padding {
        padding-top: 40px
    }
}

@media (min-width:768px) and (max-width:991px) {
    .about-section {
        padding: 40px 20px
    }
    .about-section-bottom-img {
        height: 255px;
        width: 325px
    }
    .about-section-bottom-img img {
        margin-top: 20px;
        margin-left: 20px
    }
    .about-section-bottom-desc {
        font-size: 15px;
        line-height: 23px
    }
    .about-section-bottom-padding {
        padding-top: 30px
    }
}

@media (min-width:576px) and (max-width:767px) {
    .about-section {
        padding: 30px 20px
    }
    .about-section-bottom {
        display: flex;
        justify-content: unset;
        flex-direction: column
    }
    .about-section-bottom-img {
        margin-bottom: 65px;
        height: 333px;
        width: 92%
    }
    .about-section-bottom-img img {
        margin-top: 30px;
        margin-left: 30px;
        width: 100%;
        height: 340px;
        object-fit: cover
    }
    .about-section-bottom-desc {
        font-size: 15px;
        line-height: 23px;
        max-width: 100%
    }
    .about-section-bottom-padding {
        padding-top: 30px
    }
    .about-section-img-container {
        width: 100%
    }
    .about-section-bottom-content {
        width: 100%
    }
}

@media screen and (max-width:575px) {
    .about-section {
        padding: 30px 20px
    }
    .about-section-bottom {
        display: flex;
        justify-content: unset;
        flex-direction: column
    }
    .about-section-bottom-img {
        margin-bottom: 45px;
        height: 250px;
        width: 92%
    }
    .about-section-bottom-img img {
        margin-top: 20px;
        margin-left: 20px;
        width: 100%;
        object-fit: cover;
        height: 100%
    }
    .about-section-bottom-desc {
        font-size: 15px;
        line-height: 23px;
        max-width: 100%
    }
    .about-section-bottom-padding {
        padding-top: 30px
    }
    .about-section-img-container {
        width: 100%
    }
    .about-section-bottom-content {
        width: 100%
    }
}