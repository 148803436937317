.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background: rgba(0, 0, 0, 0.801);
}

.modal-diologue {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
    max-width: 605px;
    margin: 1.75rem auto;
}

.modal-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
    background: url("../../assets/Mask\ group\ \(1\).png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    z-index: 1;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    color: #E7ECF0;
}

.modal-header .m-h {
    margin: 0px !important;
    color: #E7ECF0 !important;
    font-size: 30px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 135.023% !important;
}





.form-footer {
    display: flex;
    align-items: flex-end;
}




.error-message {
    color: red;
    font-size: 14px;
    margin-top: 4px;
}


.modal-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.9;
    background: #0B4066 !important;
}

.enquiry-btn:nth-child(1) {
    margin-right: 10px;
}

.close-button {
    all: unset;
    font-size: 30px;
}

.enquiry-btn {
    margin-top: 1.5rem;
}



.form-container2 .form-control{
    background: #0000;
    border: 1px solid #e7ecf0 ;
    color: #e7ecf0;
    font-weight: 300;
}

.form-container2 .form-control::placeholder{
    color: #e7ecf0;
}

.form-container2 label{
    color: #e7ecf0;
    font-weight: 300;
}

.form-container2 option{
    color: #000;
}

.type-area-group{
    width: 100% !important;
}




/* Responsive Styles */
@media screen and (max-width: 767px) {
    .modal-header {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .modal-diologue {
        max-width: 90%;
    }
}



