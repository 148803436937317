.why-choose-us {
    margin: 80px 150px;
}

.why-choose-us-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
}














/* Media Query */
@media (min-width:1200px) and (max-width:1400px) {
    .why-choose-us {
        margin: 60px 100px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .why-choose-us {
        margin: 40px 50px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .why-choose-us-container{
        gap: 30px;
    }
}

@media screen and (max-width:991px) {
    .why-choose-us {
        margin: 40px 20px;
    }
}

@media screen and (max-width:575px) {
    .why-choose-us-container{
        gap: 20px;
    }
}