.faq-banner {
    background: linear-gradient(rgba(16, 15, 15, 0.42), rgba(16, 15, 15, 0.42)), url(../../assets/certificates/certificates\ banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.faq-section {
    padding: 0 150px;
    padding-bottom: 80px;
}

button {
    all: unset;
}

.AccordionRoot {
    border-radius: 6px;
}


.AccordionRoot h3 {
    margin: 0;
}

.AccordionItem {
    overflow: hidden;
    margin-top: 1px;
    border-radius: 8px;
    border: 1px solid #0f153717;
    background: #fff;
    backdrop-filter: blur(10.5px);
    padding: 5px 20px;
    margin-bottom: 20px;
}



.AccordionItem:focus-within {
    position: relative;
    z-index: 1;
}

.AccordionHeader {
    display: flex;
}

.AccordionTrigger {
    font-family: inherit;
    background-color: transparent;
    padding: 10px 0px;
    height: 45px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #0B4066;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.AccordionTrigger svg {
    color: #FFB300;
}



.AccordionContent {
    overflow: hidden;
    color: #3E445E;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.AccordionContent a {
    color: #FFB300;
    text-decoration: none;
}

.AccordionContent a:hover {
    color: #ffb300b2;
}

.AccordionContent ul {
    margin-top: 1rem;
}

.AccordionContent ul li {
    display: flex;
    align-items: flex-start;
}

.l-p {
    font-weight: 500;
}

.AccordionContent ul li svg {
    color: #FFB300;
    font-size: 25px;
    margin-right: 10px;
}

.AccordionContent[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContent[data-state='closed'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentText {
    padding: 15px 0px;
}

.toggle-on,
.toggle-off {
    transition: all 0.3s ease-in-out;
}


.AccordionTrigger[data-state='open']>.toggle-off {
    display: block;
}

.AccordionTrigger[data-state='closed']>.toggle-off {
    display: none;
}

.AccordionTrigger[data-state='open']>.toggle-on {
    display: none;
}

.AccordionTrigger[data-state='closed']>.toggle-on {
    display: block;
}

@keyframes slideDown {
    from {
        height: 0;
    }

    to {
        height: var(--radix-accordion-content-height);
    }
}

@keyframes slideUp {
    from {
        height: var(--radix-accordion-content-height);
    }

    to {
        height: 0;
    }
}






@media (min-width:1200px) and (max-width:1400px) {
    .faq-section {
        padding: 0 100px;
        padding-bottom: 60px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .faq-section {
        padding: 0 50px;
        padding-bottom: 60px;
    }

    .AccordionTrigger {
        font-size: 19px;
    }
}

@media (min-width:576px) and (max-width:991px) {
    .AccordionTrigger {
        font-size: 18px;
    }
}

@media (max-width:991px) {
    .faq-section {
        padding: 0 20px;
        padding-bottom: 30px;
    }
}

@media (max-width:575px) {
    .AccordionTrigger {
        font-size: 17px;
    }
}