.heading-desc {
    position: relative;
    z-index: 1;
    color: #FFB300;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3.08px;
    text-transform: uppercase;
    padding-left: 60px;
    background: #fff;
    margin-top: 0;
    margin-bottom: 0;
}

.heading-desc::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    z-index: -1;
    border-bottom: 1px solid #FFB300;
    height: 100%;
    width: 49px;
}









/* Media Query */
@media (min-width:576px) and (max-width:991px) {
    .heading-desc{
        font-size: 14px;
    }
}

@media screen and (max-width:575px) {
    .heading-desc{
        font-size: 12px;
        padding-left: 35px;
        letter-spacing: 1.08px;
    }

    .heading-desc::before{
        width: 30px;
        top: -7px;
    }
}