.become-partner-banner {
    background: linear-gradient(rgba(16, 15, 15, 0.42), rgba(16, 15, 15, 0.42)), url(../../assets/became-partner-banner.webp);
    background-repeat: no-repeat;
    background-size: cover;
}


.become-partner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 60px 150px;
}

.partner-content {
    flex: 1 1 48%;
}

.partner-form {
    background-color: #F8F8FA;
    flex: 1 1 52%;
    padding: 40px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05) inset;
}

.partner-form .form-group {
    width: 100%;
    position: relative;
}

.partner-form .form-group .form-control {
    border: none;
    background-color: #fff;
    color: #797777;
    text-indent: 40px;
}

.partner-form .form-group .form-control-textarea {
    text-indent: 5px;
}

.partner-form .form-group .form-control::placeholder {
    color: #797777;
}

.partner-image {
    width: 100%;
    height: 400px;
    max-width: 550px;
    display: block;
    margin-bottom: 20px;
}

.become-partner-heading-desc {
    margin-top: 1.5rem;
    font-size: 16px;
    color: #696969;
    line-height: 28px;
}

.partner-info {
    max-width: 600px;
}

.partner-info h2 {
    font-size: 32px;
    margin-bottom: 10px;
    color: #0B4066;
}

.partner-info p {
    font-size: 16px;
    color: #696969;
    line-height: 28px;
}

.icon {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
}

.partner-form .text-area-group .icon {
    top: 24px;
}

.icon svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}


.contactor-container {
    margin-top: 70px;
}

.contactor-container h2 {
    font-size: 32px;
    margin-bottom: 2rem;
    color: #0B4066;
}

.contactor-container p {
    color: #696969;
    margin-top: 1rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: 0 0 30px 0 #0000001a;
    background: #c9c5c517;
    padding: 30px;
}

.contractor-item {
    width: 25%;
    margin-bottom: 20px;
    border-right: 1px solid #ffb300;
    padding: 20px;
}

.contractor-item:last-child {
    border-right: none;
}

.contractor-item h5 {
    color: #ffb300;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
}

.contractor-item a {
    color: #ffb300;
    text-decoration: none;
}

.contractor-item p {
    font-size: 16px;
    color: #696969;
    line-height: 26px;
    margin-top: 1rem;
}

@media (max-width: 767px) {
    .contractor-item {
        width: 50%;
    }

    .contractor-item:nth-child(2) {
        border-right: none;
    }
}

/* Responsive adjustments */
@media screen and (max-width: 991px) {
    .become-partner {
        margin: 30px 20px;
    }

    .partner-form {
        padding: 25px;
    }

    .become-partner {
        flex-direction: column;
    }

    .partner-content,
    .partner-form {
        flex-basis: 100%;
    }

    .partner-info {
        max-width: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .become-partner {
        margin: 40px 50px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .become-partner {
        margin: 60px 100px;
    }
}


@media screen and (max-width:575px) {

    .contactor-container h2,
    .partner-info h2 {
        font-size: 24px;
    }

    .row {
        padding: 10px;
    }

    .contactor-container {
        margin-top: 30px;
    }

    .partner-content {
        margin-bottom: 20px;
    }

}