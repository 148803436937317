.blogs-banner {
    background: linear-gradient(rgba(16, 15, 15, 0.42), rgba(16, 15, 15, 0.42)), url(../../assets/blog-blogs-banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.blogs-section {
    margin: 80px 150px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}


















/* Media Query */
@media (min-width:1200px) and (max-width:1400px) {
    .blogs-section {
        margin: 60px 100px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .blogs-section {
        margin: 40px 50px;
    }
}


@media screen and (max-width:991px) {
    .blogs-section {
        margin: 30px 20px;
        gap: 25px;
    }
}