.location-banner-container {
    background: url(../../assets/home/foreman-businessman-solar-energy-station\ 1.webp);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1
}

.location-banner-section {
    margin-top: 80px;
    padding: 60px 150px;
    display: flex;
    justify-content: space-between
}

.location-banner-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: .69;
    background: linear-gradient(180deg, rgba(0, 0, 0, .94) 0, #0b4066 55.73%, #000 100%)
}

.banner-small-title {
    position: relative;
    z-index: 1;
    color: #ffb300;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3.08px;
    text-transform: uppercase;
    padding-left: 60px;
    margin-bottom: 0
}

.banner-small-title::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    z-index: -1;
    border-bottom: 1px solid #ffb300;
    height: 100%;
    width: 49px
}

.banner-big-title {
    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 343px;
    margin-top: 0;
    margin-bottom: 2rem
}

.area {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.023%;
    letter-spacing: .84px;
    text-transform: uppercase;
    margin-right: 40px;
    color: #ffb300;
    min-width: 160px
}

.area p {
    margin-bottom: 10px
}

.loction-container {
    display: flex
}

.location {
    width: 50%
}

.contact-form {
    width: 50%
}

.form-control2 {
    background: 0 0;
    color: #fff !important;
    border: 1px solid #e7ecf08e !important
}

.form-control-textarea2 {
    height: 150px !important
}

.form-control2::placeholder {
    color: #fff !important
}

.location-btn {
    margin-top: 1.3rem !important
}

.map-1 {
    color: #ffb300;
    position: absolute;
    top: 120px;
    left: 105px;
    animation: lightUpDown 2s infinite
}

.map-2 {
    color: #ffb300;
    position: absolute;
    top: 160px;
    left: 65px;
    animation: lightUpDown 2.1s infinite
}

.map-3 {
    color: #ffb300;
    position: absolute;
    top: 160px;
    left: 165px;
    animation: lightUpDown 2.2s infinite
}

.map-4 {
    color: #ffb300;
    position: absolute;
    top: 210px;
    left: 130px;
    animation: lightUpDown 2.3s infinite
}

.map-5 {
    color: #ffb300;
    position: absolute;
    top: 265px;
    left: 90px;
    animation: lightUpDown 2.4s infinite
}

.map {
    position: relative
}

.map img {
    width: 400px
}

.location-a {
    cursor: pointer;
    text-decoration: none;
    color: #ffb300
}

@keyframes lightUpDown {

    0%,
    100% {
        transform: scale(1.4)
    }

    50% {
        transform: scale(1)
    }
}

@media (min-width:1400px) and (max-width:1500px) {
    .map img {
        width: 335px
    }

    .map-1 {
        top: 96px;
        left: 86px
    }

    .map-2 {
        top: 134px;
        left: 53px
    }

    .map-3 {
        top: 131px;
        left: 143px
    }

    .map-4 {
        top: 174px;
        left: 108px
    }

    .map-5 {
        top: 223px;
        left: 74px
    }
}

@media (min-width:1200px) and (max-width:1400px) {
    .location-banner-section {
        padding: 60px 100px;
        margin-top: 60px
    }

    .map img {
        width: 270px
    }

    .map-1 {
        top: 73px;
        left: 68px
    }

    .map-2 {
        top: 107px;
        left: 43px
    }

    .map-3 {
        top: 100px;
        left: 115px
    }

    .map-4 {
        top: 135px;
        left: 88px
    }

    .map-5 {
        top: 175px;
        left: 60px
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .location-banner-section {
        padding: 40px 50px;
        margin-top: 40px
    }

    .banner-small-title {
        margin: 0
    }

    .map img {
        width: 230px
    }

    .map-1 {
        top: 60px;
        left: 57px
    }

    .map-2 {
        top: 90px;
        left: 35px
    }

    .map-3 {
        top: 80px;
        left: 95px
    }

    .map-4 {
        top: 110px;
        left: 75px
    }

    .map-5 {
        top: 148px;
        left: 48px
    }
}

@media (min-width:768px) and (max-width:991px) {
    .location-banner-section {
        padding: 30px 20px;
        margin-top: 30px
    }

    .banner-small-title {
        margin: 0;
        font-size: 12px
    }

    .banner-big-title {
        font-size: 24px
    }

    .area {
        margin-right: 0;
        font-size: 11px
    }

    .map img {
        width: 185px
    }

    .map-1 {
        top: 45px;
        left: 44px
    }

    .map-2 {
        top: 65px;
        left: 27px
    }

    .map-3 {
        top: 65px;
        left: 77px
    }

    .map-4 {
        top: 85px;
        left: 60px
    }

    .map-5 {
        top: 115px;
        left: 38px
    }
}

@media screen and (max-width:767px) {
    .location-banner-section {
        padding: 30px 20px;
        margin-top: 30px;
        flex-direction: column
    }

    .banner-small-title {
        margin: 0;
        font-size: 12px;
        letter-spacing: 1.08px;
        padding-left: 30px
    }

    .banner-small-title::before {
        top: -7px;
        width: 25px
    }

    .banner-big-title {
        font-size: 20px
    }

    .location {
        width: 100%;
        margin-bottom: 30px
    }

    .contact-form {
        width: 100%
    }
}

@media (min-width:480px) and (max-width:620px) {
    .map img {
        width: 280px
    }

    .area {
        margin-right: 10px;
        font-size: 12px
    }

    .map-1 {
        top: 77px;
        left: 70px
    }

    .map-2 {
        top: 110px;
        left: 45px
    }

    .map-3 {
        top: 105px;
        left: 120px
    }

    .map-4 {
        top: 140px;
        left: 90px
    }

    .map-5 {
        top: 185px;
        left: 60px
    }
}

@media (min-width:380px) and (max-width:479px) {
    .map img {
        width: 200px
    }

    .area {
        margin-right: 0;
        font-size: 10px
    }

    .map-1 {
        top: 50px;
        left: 48px
    }

    .map-2 {
        top: 70px;
        left: 30px
    }

    .map-3 {
        top: 70px;
        left: 80px
    }

    .map-4 {
        top: 94px;
        left: 65px
    }

    .map-5 {
        top: 125px;
        left: 40px
    }
}

@media screen and (max-width:379px) {
    .map img {
        width: 140px
    }

    .area {
        margin-right: 0;
        font-size: 10px
    }

    .map-1 {
        top: 29px;
        left: 31px
    }

    .map-2 {
        top: 45px;
        left: 19px
    }

    .map-3 {
        top: 42px;
        left: 56px
    }

    .map-4 {
        top: 61px;
        left: 44px
    }

    .map-5 {
        top: 81px;
        left: 28px
    }
}