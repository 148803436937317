
.product-banner-solar-panels{
    background: linear-gradient(rgba(16, 15, 15, .42), rgba(16, 15, 15, .42)), url(../../assets/products\ banner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.product-banner-inverters{
    background: url(../../assets/inverter.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.product-banner-cables-wires{
    background: url(../../assets/cables.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.products-section {
    margin: 80px 150px
}

.custom-text-product {
    top: 59%
}

.products-section .products-t {
    color: #0b4066;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}

.products-section p {
    max-width: 1237px;
    color: #696969;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 1rem 0
}

.products-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    gap: 30px
}

.products-item {
    width: 31%;
    cursor: pointer
}

.products-item-img {
    padding: 50px;
    border-radius: 3px;
    background: #ebf2fd;
    display: flex;
    justify-content: center;
    align-items: center
}

.products-item-img img {
    max-width: 100%
}

.products-item a {
    text-decoration: none
}

.products-item h5 {
    color: #0b4066;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: .5rem
}

.products-container2 {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.products-by-category-container h2 {
    color: #0b4066;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 1.5rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e7ecf0
}

.without-partner {
    margin-top: 2rem
}

.p-description p {
    font-size: 20px;
    margin-bottom: 2rem
}

.p-btn {
    padding: 10px 20px
}

.category-content-section {
    margin-top: 60px
}

.category-content-section h2 {
    color: #0b4066;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0
}

.category-content-section h3 {
    color: #0b4066;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0
}


.category-content-section p {
    font-size: 20px
}

.category-content-section ul {
    padding-left: 0;
    margin: 1.3rem 0
}

.category-content-section ul li {
    list-style: none;
    color: #696969;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: .5rem 0
}

.category-content-section a{
   text-decoration: none;
   color: #ffb300;
}

@media (min-width:1200px) and (max-width:1400px) {
    .products-section {
        margin: 60px 100px
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .products-section {
        margin: 60px 50px
    }
}

@media (min-width:768px) and (max-width:991px) {
    .products-section .products-t {
        font-size: 32px
    }

    .products-by-category-container h2 {
        font-size: 26px
    }

    .category-content-section h2 {
        font-size: 26px
    }

    .products-section p {
        font-size: 21px
    }

    .products-item h5 {
        font-size: 22px
    }

    .products-container {
        gap: 25px
    }
}

@media (min-width:576px) and (max-width:767px) {
    .products-section .products-t {
        font-size: 36px
    }

    .products-by-category-container h2 {
        font-size: 26px
    }

    .category-content-section h2 {
        font-size: 26px
    }

    .products-section p {
        font-size: 20px
    }

    .products-item {
        width: 47%
    }

    .products-item h5 {
        font-size: 20px
    }
}

@media screen and (max-width:575px) {
    .products-section .products-t {
        font-size: 32px
    }

    .products-by-category-container h2 {
        font-size: 26px
    }

    .category-content-section h2 {
        font-size: 26px
    }

    .products-section p {
        font-size: 18px
    }

    .products-item {
        width: 100%
    }

    .products-item h5 {
        font-size: 20px
    }
}

@media screen and (max-width:991px) {
    .products-section {
        margin: 30px 20px
    }
}