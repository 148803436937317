.home-banner-slider-img {
    width: 100%;
    object-fit: cover;
    margin-bottom: .5rem;
    height: 100vh;
}

.home-slider {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.home-slider .c-container {
    height: 100%;
}

.slider-text {
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 0 150px;
}

.slider-text h2 {
    font-size: 56px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.slider-text p {
    font-size: 26px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.home-slider:focus-visible {
    outline: 0 !important
}

.slick-slide img {
    margin-bottom: .7rem
}

.custom-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center
}

.custom-title {
    color: #fff;
    text-align: center;
    font-size: 96px;
    font-style: normal;
    font-weight: 600;
    line-height: 98px
}

.color-dot {
    font-size: 80px;
    color: #ffb300
}

/* .slider-1,
.slider-2,
.slider-3 {
    background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==');
} */
/* .slider-1 {
    background-image: url('../../assets/home-banner/kesri-banner-1-min.webp');
}
.slider-2 {
    background-image: url('../../assets/home-banner/kesri-banner-2-min.webp');
}
.slider-3 {
    background-image: url('../../assets/home-banner/kesri-banner-3-min.webp');
} */

.slider {
    background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==');
    background-size: cover;
    background-position: center;
}

.slider-loaded.slider-1 {
    background-image: url('../../assets/home-banner/kesri-banner-1-min.webp');
}

.slider-loaded.slider-2 {
    background-image: url('../../assets/home-banner/kesri-banner-2-min.webp');
}

.slider-loaded.slider-3 {
    background-image: url('../../assets/home-banner/kesri-banner-3-min.webp');
}

.slider-loaded-mobile.slider-1 {
    background-image: url('../../assets/home-banner/kesri-banner-1-mobile-min.webp');
}

.slider-loaded-mobile.slider-2 {
    background-image: url('../../assets/home-banner/kesri-banner-2-mobile-min.webp');
}

.slider-loaded-mobile.slider-3 {
    background-image: url('../../assets/home-banner/kesri-banner-3-mobile-min.webp');
}


@media (min-width:1200px) and (max-width:1400px) {
    .custom-title {
        font-size: 84px
    }

    .slider-text {
        padding: 0 100px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .custom-title {
        font-size: 64px;
        line-height: 72px
    }

    .slider-text {
        padding: 0 50px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .custom-title {
        font-size: 48px;
        line-height: 50px
    }

    .slider-text {
        padding: 0 20px;
    }

    .home-slider {
        height: 500px
    }
}

@media (min-width:576px) and (max-width:767px) {
    .custom-title {
        font-size: 36px;
        line-height: 28px
    }

    .slider-text {
        padding: 0 20px;
    }

    .home-slider {
        height: 400px
    }

    .slider-text h2 {
        font-size: 32px;
    }

    .slider-text p {
        font-size: 18px;
        font-weight: bold;
    }
}

@media screen and (max-width:575px) {
    .custom-title {
        font-size: 26px;
        line-height: 26px
    }

    .color-dot {
        font-size: 24px
    }

    .home-banner-slider-img {
        height: 60vh
    }

    /* .slider-1 {
        background-image: url('../../assets/home-banner/kesri-banner-1-mobile-min.webp');
    }
    .slider-2 {
        background-image: url('../../assets/home-banner/kesri-banner-2-mobile-min.webp');
    }
    .slider-3 {
        background-image: url('../../assets/home-banner/kesri-banner-3-mobile-min.webp');
    } */
    .home-slider {
        height: 320px
    }

    .slider-text {
        padding: 0 20px;
    }

    .slider-text h2 {
        font-size: 28px;
    }

    .slider-text p {
        font-size: 16px;
        font-weight: bold;
    }
}