.breadcrumb-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 400;
}

.breadcrumb {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.breadcrumb-item {
    display: inline;
}

.breadcrumb-item a {
    color: #fff;
    text-decoration: none;
}

.breadcrumb-icon {
    vertical-align: middle;
    margin: 0 3px;
}

.breadcrumb-item.active {
    color: #FFB300;
}










@media (max-width:575px){
    .breadcrumb-container {
        padding: 0;
        padding-top: 5px;
        font-size: 12px;
    }
}