.location-banner {
    background: linear-gradient(rgba(16, 15, 15, 0.42), rgba(16, 15, 15, 0.42)), url(../../assets/gallery-banner2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.location-page-section {
    padding: 0 150px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.location-name {
    color: #0B4066;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 1.5rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E7ECF0;
}

.location-desc {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.location-desc a {
    text-decoration: none;
    color: #FFB300;
}

.location-desc a:hover{
    color: #ffb300a2;
}









@media (min-width:1200px) and (max-width:1400px) {
    .location-page-section {
        padding: 0px 100px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .location-page-section {
        padding: 0px 50px;
    }
}

@media (max-width:991px) {
    .location-page-section {
        padding: 0px 20px;
    }

    .location-name {
        font-size: 26px;
    }
}