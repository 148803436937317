.contact-banner {
    background: linear-gradient(rgba(16, 15, 15, 0.42), rgba(16, 15, 15, 0.42)), url(../../assets/contact\ banner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner-heading-section2 {
    margin-top: 80px;
}

.contact-section {
    margin: 80px 150px;
    margin-top: 40px;
    display: flex;
    gap: 30px;
}

.contact-page-form {
    width: 50%;
    padding: 60px;
}


.contact-info-card {
    width: 50%;
    border-radius: 6px;
    background: #EBF2FD;
    padding: 20px;
}



.contact-info-detail {
    padding: 20px;
    display: flex;
    gap: 30px;
}

.contact-info-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #CED9E0;
}

.contact-info-header h3 {
    color: #0B4066;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0.5rem 0;
}

.contact-info-header h6 {
    color: #0B4066;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0.5rem 0;
}


.contact-info-data {
    margin-top: 1rem;
}

.contact-info-data a{
    text-decoration: none;
}

.contact-info-data li {
    display: flex;
    list-style: none;
    margin-bottom: 15px;
}

.info-data-logo {
    margin-right: 10px;
}

.info-content h6 {
    margin: 0;
    color: #0B4066;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.023%;
}

.info-content p,
.info-content a {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.023%;
    margin-top: 0.3rem;
    max-width: 323px;
}

.banner-big-title-contact {
    color: #0B4066;
}

.form-container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}

.form-group {
    flex: 0 0 auto;
    width: 50%;
    max-width: 100%;
    padding-right: calc(1.5rem * .5);
    padding-left: calc(1.5rem * .5);
    margin-top: 0;
    margin-bottom: 1.5rem;
}

.form-group label {
    margin-bottom: 6px;
    display: inline-block;
}

.form-group select {
    appearance: menulist;
}

.text-area-group {
    flex: 0 0 auto;
    width: 100%;
}

.form-control-textarea {
    min-height: 135px;
}


.form-control {
    height: 48px;
    outline: none;
    border-radius: 3px;
    border: 1px solid #DDD;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #696969;
    font-family: Outfit;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contact-page-map {
    width: 50%;
    position: relative;
}


.contact-form-container {
    margin: 0 150px;
    margin-bottom: 80px;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.13);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}


.contact-page-map-bg {
    background-color: #0B4066;
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}


.contact-page-location {
    position: relative;
    z-index: 1;
    padding: 60px 0px;
}

.map-bg {
    position: absolute;
    right: 0;
    bottom: 0;
}






@media (min-width:1200px) and (max-width:1400px) {
    .contact-section {
        margin: 60px 100px;
    }

    .banner-heading-section2 {
        margin-top: 60px;
    }

    .contact-form-container {
        margin: 60px 100px;
    }

    .contact-page-form {
        padding: 60px 40px;
    }

    .contact-map {
        height: 620px;
        width: 100%;
    }

    .contact-page-location {
        padding-right: 60px;
    }

}

@media (min-width:992px) and (max-width:1199px) {

    .contact-section {
        margin: 40px 50px;
    }

    .contact-form-container {
        margin: 0px 50px;
        margin-bottom: 60px;
    }

    .contact-info {
        padding-right: 30px;
    }


    .contact-page-form {
        padding: 30px;
    }

    .contact-map {
        height: 600px;
        width: 100%;
    }

    .contact-page-location {
        padding-right: 60px;
    }

}


@media (min-width:768px) and (max-width:991px) {

    .banner-heading-section2 {
        margin-top: 40px;
    }

    .contact-form-container {
        margin: 0 20px;
        margin-bottom: 40px;
    }

    .contact-page-form {
        padding: 30px 20px;
    }

    .contact-map {
        height: 570px;
        width: 100%;
    }

    .contact-page-location {
        padding-right: 50px;
    }


}

@media screen and (max-width:991px) {

    .contact-section {
        margin: 30px 20px;
    }

    .contact-info {
        padding-right: 10px;
    }

    .contact-info-data li {
        margin-bottom: 7px;
    }


    .info-content p {
        font-size: 15px;
    }

    .info-data-logo img {
        width: 40px;
    }

}

@media screen and (max-width:767px) {
    .banner-heading-section2 {
        margin-top: 30px;
    }

    .contact-form-container {
        margin: 0 20px;
        margin-bottom: 30px;
        flex-direction: column;
    }

    .contact-page-form {
        padding: 30px;
    }

    .contact-page-map {
        width: 100%;
    }

    .contact-page-map-bg {
        overflow: hidden;
    }


}

@media (min-width:576px) and (max-width:767px) {

    .contact-page-form {
        width: 100%;
        margin-bottom: 30px;
    }

    .contact-info {
        width: 98%;
        padding-right: 0;
    }

    .info-data-logo img {
        width: 40px;
    }

    .contact-map {
        height: 340px;
        width: 100%;
    }

    .contact-page-location {
        padding: 40px;
    }

}

@media screen and (max-width:575px) {
    .contact-page-form {
        width: 100%;
    }

    .form-group {
        width: 100%;
    }

    .contact-info {
        width: 98%;
        padding-right: 0;
    }

    .contact-info-header h3 {
        font-size: 20px;
    }

    .contact-info-header h6 {
        font-size: 16px;
    }

    .info-data-logo img {
        width: 35px;
    }

    .contact-section {
        flex-direction: column;
        gap: 20px;
    }

    .contact-info-card {
        width: 100%;
    }

    .contact-map {
        height: 260px;
        width: 100%;
    }

    .contact-page-location {
        padding: 20px;
    }

}