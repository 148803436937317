.why-choose-us-item {
    display: flex;
    width: 30.33%;
}

.why-choose-img {
    margin-right: 25px;
}

.why-choose-content {
    display: flex;
    flex-direction: column;
}

.why-choose-content .why-c-h {
    color: #0B4066;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-height: 70px;
}

.why-choose-content .why-c-d {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    max-width: 267px;
    margin-bottom: 0;
}

















/* Media Query */
@media (min-width:992px) and (max-width:1400px) {
    .why-choose-content .why-c-h{
        min-height: 95px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .why-choose-content .why-c-h {
        min-height: 90px;
    }
}

@media (min-width:992px) and (max-width:1400px) {
    .why-choose-img img {
        width: 65px;
    }
}

@media (min-width:576px) and (max-width:991px) {
    .why-choose-img {
        margin-right: 15px;
    }

    .why-choose-img img {
        width: 55px;
    }

    .why-choose-content .why-c-h {
        font-size: 22px;
    }

    .why-choose-content .why-c-d {
        font-size: 15px;
        line-height: 22px;
    }
}


@media screen and (max-width:767px) {
    .why-choose-us-item {
        width: 46%;
    }
}

@media screen and (max-width:575px) {
    .why-choose-img {
        margin-right: 10px;
    }

    .why-choose-img img {
        width: 45px;
    }

    .why-choose-content .why-c-h{
        font-size: 17px;
    }

    .why-choose-content .why-c-d {
        font-size: 14px;
        line-height: 20px;
    }
}