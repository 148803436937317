.about-banner {
    background: linear-gradient(rgba(16, 15, 15, .42), rgba(16, 15, 15, .42)), url(../../assets/about/image\ 1.webp);
    height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative
}

.custom-text-about {
    top: 52%
}

.about-section-bottom-desc2 {
    max-width: 100%;
    margin-bottom: 1rem
}

.about-section-bottom-heading {
    color: #0b4066;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -.8px;
    max-width: 594px;
    margin-top: 0;
    margin-bottom: 1.5rem
}

.director-detail-small-desc {
    margin: 1rem 0
}

.loction-container2 {
    width: 50%;
    padding: 40px 0
}

.location-content {
    background: #ebf2fd
}

.about-map-section {
    margin-left: 150px;
    display: flex
}

.director-info {
    background: #0b4066;
    padding: 40px 30px;
    width: 50%
}

.director-desc {
    display: flex
}

.director-img {
    margin-right: 15px
}

.director-img img {
    border-radius: 50%
}

.director-title .director-name {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0
}

.director-title p {
    color: #ffb300;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 151.523%;
    margin: 0
}

.director-detail p {
    max-width: 528px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px
}

.director-detail-point {
    margin-bottom: 1rem
}

.point-right-arrow {
    color: #ffb300;
    margin-right: 15px;
    font-weight: 700
}

.about-area .location-a {
    color: #696969
}

.machinary-facilitity .m-f-t {
    color: #0b4066;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 1rem 0
}

.machine-items div {
    display: inline-block;
    width: 50%;
    color: var(--secondary, #0b4066);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px
}

.machine-items div img {
    margin-right: 15px;
    vertical-align: middle
}

.we-offers {
    background: #ebf2fd
}

.we-offers-container {
    padding: 60px 150px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px
}

.offer-item-img {
    border-radius: 8px;
    padding: 50px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.offer-item-img img {
    height: 80px;
    width: 80px;
    max-width: 100%;
    object-fit: contain
}

.offer-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 18%
}

.offer-item p {
    color: #0b4066;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    min-height: 40px;
    margin-top: 1rem
}

.gallery-section {
    margin: 60px 150px;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px
}

.banner-heading-section {
    margin: 40px 150px;
    margin-top: 0
}

.gallery {
    width: 30%;
    box-shadow: rgba(60, 64, 67, .3) 0 1px 2px 0, rgba(60, 64, 67, .15) 0 2px 6px 2px;
    padding: 10px
}

.gallery-img-div {
    overflow: hidden
}

.gallery-img {
    transition: all .3s ease-in-out;
    object-fit: contain;
    height: 100%;
    width: 100%
}

.gallery:hover .gallery-img {
    transform: scale(1.1);
    transition: all .3s ease-in-out
}

.certificate-section {
    margin: 80px 150px;
    margin-top: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px
}

.certificate-img {
    width: 44%;
    padding: 30px;
    border-radius: 3px;
    background: #ebf2fd;
    display: flex;
    justify-content: center;
    align-items: center
}

@media (min-width:1401px) and (max-width:1600px){
    .about-banner {
        height: 470px;
    }
}


@media (min-width:1200px) and (max-width:1400px) {
    .about-map-section {
        margin-left: 100px
    }

    .about-section-bottom2 {
        margin-top: 0
    }

    .about-banner {
        height: 430px
    }

    .we-offers-container {
        padding: 60px 100px
    }

    .offer-item p {
        font-size: 16px
    }

    .gallery-section {
        margin: 60px 100px;
        margin-top: 0;
        gap: 20px
    }

    .banner-heading-section {
        margin: 40px 100px;
        margin-top: 0
    }

    .certificate-section {
        margin: 60px 100px;
        margin-top: 0
    }

    .certificate-img {
        width: 42%
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .about-banner {
        height: 400px
    }

    .about-section-bottom2 {
        margin-top: 0
    }

    .about-section-bottom-heading {
        font-size: 36px
    }

    .about-map-section {
        margin-left: 50px
    }

    .we-offers-container {
        padding: 30px 50px
    }

    .offer-item p {
        font-size: 14px
    }

    .offer-item-img {
        padding: 40px
    }

    .machine-items div {
        font-size: 16px
    }

    .gallery-section {
        margin: 60px 50px;
        margin-top: 0;
        gap: 12px
    }

    .banner-heading-section {
        margin: 40px 50px;
        margin-top: 0
    }

    .certificate-section {
        margin: 40px 50px;
        margin-top: 0;
        gap: 20px
    }

    .certificate-img {
        width: 42%
    }

    .certificate-img img {
        width: 100%
    }
}

@media (min-width:768px) and (max-width:991px) {
    .about-map-section {
        margin-left: 20px
    }

    .about-banner {
        height: 300px
    }

    .about-section-bottom2 {
        margin-top: 20px
    }

    .about-section-bottom-heading {
        font-size: 26px
    }

    .director-title .director-name {
        font-size: 16px
    }

    .director-title p {
        font-size: 14px
    }

    .director-detail p {
        font-size: 14px
    }

    .we-offers-container {
        padding: 30px 20px
    }

    .offer-item p {
        font-size: 15px
    }

    .offer-item-img {
        padding: 30px
    }

    .machine-items div {
        font-size: 13px
    }

    .gallery {
        width: 45%
    }

    .certificate-section {
        margin: 40px 20px;
        margin-top: 0
    }

    .certificate-img {
        width: 41%;
        padding: 25px
    }

    .certificate-img img {
        width: 100%
    }
}

@media (min-width:576px) and (max-width:767px) {
    .we-offers-container {
        padding: 30px 20px;
        justify-content: unset;
        gap: 30px
    }

    .offer-item-img {
        padding: 40px
    }

    .offer-item {
        width: 29%
    }

    .offer-item p {
        font-size: 14px
    }

    .gallery-section {
        gap: 12px
    }

    .gallery {
        width: 45%
    }

    .certificate-section {
        margin: 30px 20px;
        margin-top: 0;
        gap: 25px
    }

    .certificate-img {
        width: 40%;
        padding: 20px
    }

    .certificate-img img {
        width: 100%
    }
}

@media screen and (max-width:991px) {
    .gallery-section {
        margin: 30px 20px;
        margin-top: 0
    }

    .banner-heading-section {
        margin: 30px 20px;
        margin-top: 0
    }

    .gallery-image-section {
        margin-top: 30px;
        gap: 20px
    }
}

@media screen and (max-width:767px) {
    .about-section-bottom2 {
        flex-direction: column-reverse
    }

    .about-map-section {
        margin-left: 0
    }
}

@media screen and (max-width:767px) {
    .loction-container2 {
        padding-left: 20px
    }

    .about-map-section {
        flex-direction: column
    }

    .about-banner {
        height: 200px
    }

    .about-section-bottom-heading {
        font-size: 24px
    }

    .director-info {
        padding: 20px;
        width: unset
    }

    .director-img {
        margin-right: 10px
    }

    .director-img img {
        width: 40px
    }

    .director-title .director-name {
        font-size: 20px;
        margin-top: 0
    }

    .director-title p {
        font-size: 15px
    }

    .director-detail p {
        font-size: 16px;
        line-height: 23px
    }

    .director-detail-point {
        margin: .3rem 0
    }

    .point-right-arrow {
        margin-right: 5px
    }
}

@media screen and (max-width:575px) {
    .we-offers-container {
        padding: 30px 20px;
        flex-wrap: wrap;
        gap: 30px
    }

    .offer-item {
        width: 45%
    }

    .offer-item-img img {
        height: 90px;
        width: 90px
    }

    .offer-item-img {
        padding: 30px
    }

    .offer-item p {
        font-size: 15px
    }

    .machine-items div {
        font-size: 13px
    }

    .machine-items div img {
        width: 20px;
        margin-right: 1px
    }

    .gallery {
        width: 100%
    }

    .gallery-section {
        gap: 20px
    }

    .certificate-section {
        margin: 30px 20px;
        margin-top: 0
    }

    .certificate-img {
        width: 100%;
        padding: 20px
    }

    .certificate-img img {
        width: 100%
    }
}