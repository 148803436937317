footer {
    background: #0b4066;
    color: #e8e8e8
}

.footer-top {
    border-bottom: 1px solid rgba(255, 255, 255, .3)
}

.footer-top-content {
    padding: 30px 150px;
    display: flex;
    justify-content: space-between
}

.footer-container {
    padding: 40px 150px;
    position: relative;
    z-index: 1
}

.footer-top-item {
    display: flex;
    align-items: flex-start
}

.footer-top-item a {
    text-decoration: none;
    color: #e8e8e8
}

.footer-top-item img {
    margin-right: 15px
}

.item-info {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135.023%
}

.info-title {
    margin: 0
}

.info-desc {
    font-display: swap;
    margin: 0
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.footer-logo img {
    width: 253.393px;
    flex-shrink: 0
}

.footer-col-2 {
    width: 25%;
    border-right: 1px solid rgba(255, 255, 255, .2)
}

.footer-col {
    width: 15%
}

.footer-col:nth-child(3),
.footer-col:nth-child(4) {
    border-right: none
}

.footer-desc {
    max-width: 319px;
    font-display: swap;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 151.523%
}

.footer-icons {
    margin-top: 2rem
}

.footer-icon {
    margin-right: 15px;
    cursor: pointer;
    height: 20px
}

.footer-items-title {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-bottom: 1.5rem
}

.footer-items {
    padding: 0
}

.footer-item {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    cursor: pointer;
    text-transform: capitalize
}

.footer-item a {
    color: #e8e8e8;
    text-decoration: none
}

.footer-item a:hover {
    color: #ffb300
}

.footer-end {
    border-top: 1px solid rgba(255, 255, 255, .3);
    padding: 20px 0
}

.footer-end-desc {
    margin: 0;
    text-align: center
}

.company-link {
    text-decoration: none;
    color: #e8e8e8
}

.company-link:hover {
    text-decoration: underline
}

.location-info-desc {
    font-display: swap;
    max-width: 180px
}

@media (min-width:1200px) and (max-width:1400px) {
    .footer-top-content {
        padding: 30px 100px
    }

    .footer-container {
        padding: 40px 100px
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .footer-top-content {
        padding: 30px 50px
    }

    .footer-logo img {
        width: 223.393px;
        height: 44.785px
    }

    .footer-container {
        padding: 40px 50px
    }
}

@media (min-width:768px) and (max-width:991px) {
    .footer-top-content {
        padding: 20px
    }

    .footer-top-item img {
        width: 30px;
        margin-right: 10px
    }

    .info-title {
        font-size: 15px
    }

    .info-desc {
        font-size: 13px
    }

    .footer-logo img {
        width: 150.393px
    }

    .footer-container {
        padding: 20px 20px
    }

    .footer-desc {
        font-size: 15px
    }

    .footer-items-title {
        font-size: 20px;
        margin-bottom: 1rem
    }

    .footer-item {
        font-size: 14px;
        line-height: 30px
    }

    .footer-end-desc {
        font-size: 15px
    }
}

@media (min-width:576px) and (max-width:767px) {
    .footer-top-content {
        padding: 20px
    }

    .footer-top-item img {
        width: 25px;
        margin-right: 5px
    }

    .info-title {
        font-size: 12px
    }

    .info-desc {
        font-size: 10px
    }

    .footer-logo img {
        width: 115.393px
    }

    .footer-container {
        padding: 20px 20px
    }

    .footer-desc {
        font-size: 15px
    }

    .footer-items-title {
        font-size: 20px;
        margin-bottom: 1rem
    }

    .footer-item {
        font-size: 15px;
        line-height: 29px
    }

    .footer-end-desc {
        font-size: 13px
    }

    .footer-icon {
        margin-right: 5px
    }
}

@media screen and (max-width:767px) {
    .footer-top-content {
        padding: 20px;
        padding-bottom: 0;
        flex-wrap: wrap
    }

    .location-info-desc {
        max-width: 100%
    }
}

@media screen and (max-width:575px) {
    .footer-top-content {
        padding: 20px;
        padding-bottom: 0;
        flex-wrap: wrap
    }

    .footer-top-item {
        margin-bottom: 15px;
        width: 100%;
        align-items: flex-start
    }

    .footer-logo {
        margin-bottom: 15px
    }

    .footer-top-item img {
        width: 30px;
        margin-right: 10px
    }

    .info-title {
        font-size: 14px
    }

    .info-desc {
        font-size: 15px
    }

    .footer-logo img {
        width: 180.393px
    }

    .footer-container {
        padding: 10px 20px
    }

    .footer-desc {
        font-size: 15px
    }

    .footer-items-title {
        font-size: 20px;
        margin-bottom: .6rem
    }

    .footer-items {
        margin-bottom: 20px
    }

    .footer-item {
        font-size: 15px;
        line-height: 29px
    }

    .footer-end-desc {
        font-size: 15px
    }

    .footer-icon {
        margin-right: 5px;
        width: 25px
    }

    .footer-col-2 {
        width: 50%;
        margin-bottom: 20px
    }

    .footer-col {
        width: 50%
    }

    .footer-col:nth-child(2),
    .footer-col:nth-child(4) {
        padding-left: 35px
    }

    .footer-icons {
        margin-top: 1rem
    }

    .footer-end {
        padding: 10px 20px
    }
}