.product-detail-banner {
    background: linear-gradient(rgba(16, 15, 15, 0.42), rgba(16, 15, 15, 0.42)), url(../../assets/product\ banner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.product-detail-section {
    margin: 80px 150px;
    display: flex;
    justify-content: space-between;
}

.product-img {
    border-radius: 3px;
    background: #EBF2FD;
    margin-bottom: 10px;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-img-2 {
    padding: 0px;
    height: 576px;
}

.product-img-2 img {
    width: 100%;
    margin-bottom: 0;
}

.product-img-container {
    width: 50%;
    height: 100%;
    padding-left: 106px;
}

.product-info {
    width: 48%;
}

.product-info h2,
.product-detail-title {
    margin-top: 0;
    margin-left: 0;
    color: #0B4066;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1rem;
}

.product-info p {
    max-width: 612px;
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-left: 0;
    margin-top: 3px;
    margin-bottom: 0.5rem;
}

.product-info ul {
    padding: 0;
    padding-left: 20px;
}

.product-info ul li {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.product-info ul li::marker {
    color: #FFB300;
}

.product-info h3 {
    color: #0B4066;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 1rem !important;
}

.related-product-section {
    margin: 80px 150px;
}

.related-product-section h2 {
    color: #0B4066;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid #E7ECF0;
    padding-bottom: 1rem;
}

.related-products {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    gap: 30px;
}


.page-img img {
    width: 100%;
}



.slick-thumb {
    top: 0 !important;
    left: -112px !important;
    display: flex !important;
    flex-direction: column !important;
    bottom: unset !important;
}

.slick-thumb li {
    width: 100px !important;
    height: 100px !important;
    background: #EBF2FD;
    transition: all 0.4s;
    margin-bottom: 14px;
}


.slick-thumb li:hover {
    transform: scale(1.1);
    transition: all 0.4s;
}

.slick-thumb .slick-active {
    border: 2px solid #FFB300;
}

.page-img {
    outline: none;
}

.common-btn-blue {
    padding: 14px 24px;
    margin: 0;
    color: #0B4066;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.023%;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    background: linear-gradient(to right, #EBF2FD, #ebf2fdc4, #ebf2fd62);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-size: 300% 100%;
    transition: all .4s ease-in-out;
}

.common-btn-blue a {
    text-decoration: none;
    color: #0B4066;
    display: flex;
}

.common-btn-blue:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
    animation: pulse2 1s;
    box-shadow: 0 0 0 1em transparent;
}

.common-btn-blue:focus {
    outline: none;
    animation: pulse2 1s;
    box-shadow: 0 0 0 1em transparent;
}

.btn-flex {
    display: flex;
}

.product-detail-btns {
    display: flex;
    margin-top: 30px;
    gap: 20px;
    flex-wrap: wrap;
}

@keyframes pulse2 {
    0% {
        box-shadow: 0 0 0 0 #e4edfc;
    }
}

.product-detail-title {
    text-transform: uppercase;
    font-size: 24px !important;
}

.p-detail-btn {
    align-items: center;
    font-size: 15px;
}







/* Media Query */
@media (min-width:1200px) and (max-width:1400px) {
    .product-detail-section {
        margin: 60px 100px;
    }

    .related-product-section {
        margin: 50px 100px;
    }

    .product-info {
        width: 45%;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .product-detail-section {
        margin: 40px 50px;
    }

    .related-product-section {
        margin: 40px 50px;
    }

    .product-info {
        width: 45%;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .product-detail-section {
        margin: 30px 20px;
    }

    .related-product-section {
        margin: 30px 20px;
    }

    .product-img img {
        width: 100%;
    }

    .product-img {
        padding: 30px;
    }

    /* .product-img-2 {
        height: 400px;
    } */

    .product-img-2 img {
        width: 310px;
    }

    .product-img-container {
        width: 46%;
    }

    .related-products {
        gap: 25px;
    }

}

@media (min-width:576px) and (max-width:767px) {
    .product-detail-section {
        margin: 30px 20px;
    }

    .related-product-section {
        margin: 30px 20px;
    }

    /* .product-img-2 {
        height: 380px;
    } */

    .product-img-container {
        width: 46%;
    }

    .product-detail-btns {
        gap: 3px;
    }

    .common-btn-blue {
        font-size: 13px;
        padding: 0px 8px;
        align-items: center;
    }

    .p-detail-btn {
        align-items: center;
        font-size: 13px;
    }
}

@media (min-width:481px) and (max-width:767px) {
    .product-img img {
        width: 100%;
    }

    .product-img {
        padding: 30px;
    }

    .product-img-2 img {
        width: 310px;
    }
}

@media screen and (max-width:991px) {

    .slick-thumb {
        bottom: -100px !important;
        left: 0 !important;
        flex-direction: row !important;
        top: unset !important;
    }

    .slick-thumb li {
        height: 70px !important;
        width: 70px !important;
    }

    .product-img-container {
        padding-left: 0;
        margin-bottom: 100px;
    }


}


@media screen and (max-width:575px) {
    .product-detail-section {
        margin: 30px 20px;
        flex-direction: column;
    }


    .product-img-2 {
        height: 100%;
        padding: 30px;
    }


    .product-info h2 {
        font-size: 22px;
        margin-top: 20px;
    }

    .related-product-section {
        margin: 30px 20px;
    }

    .product-info {
        width: 100%;
    }

    .product-img-container {
        width: 100%;
        padding-left: 0;
        margin-bottom: 100px;
    }

    .slick-thumb {
        bottom: -100px !important;
        left: 0 !important;
        flex-direction: row !important;
        top: unset !important;
    }

    .slick-thumb li {
        height: 70px !important;
        width: 70px !important;
    }

    .p-detail-btn {
        padding: 11px !important;
        font-size: 13px !important;
        align-items: center;
    }

    .common-btn-blue {
        font-size: 13px;
    }
}