.blog-card-title {
    color: #0b4066;
    font-size: 28px;
    line-height: 33px;
    font-style: normal;
    font-weight: 600;
    max-width: 401px;
    margin-top: 1.5rem;
}

.blog-section-card {
    position: relative;
    width: 31%
}

.blog-section-card a {
    text-decoration: none
}

.blog-card-desc {
    color: #696969;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    max-width: 401px;
    margin: 1rem 0
}

.blog-card-btn {
    color: #0b4066;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: .9px;
    text-transform: uppercase;
    text-decoration: underline 2px solid #FFB300;
    text-underline-offset: 7px;
    cursor: pointer
}

.blog-btn {
    position: relative;
    width: 200px;
    margin-top: -25px !important;
    font-size: 14px;
    margin-bottom: 7px !important
}

.blog-section-card img {
    width: 100%;
    display: block;
    border-radius: 3px
}


@media (min-width:1401px) {
    .blog-card-desc {
        min-height: 155px
    }

    .blog-card-title {
        min-height: 100px;
    }
}

@media (min-width:1200px) and (max-width:1400px) {
    .blog-card-title {
        font-size: 28px;
        line-height: 33px;
        min-height: 133px
    }

    .blog-card-desc {
        font-size: 16px;
        padding-right: 10px;
        min-height: 156px
    }

    .blog-card-btn {
        font-size: 14px
    }

    .blog-btn {
        width: 170px
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .blog-card-title {
        font-size: 24px;
        line-height: 30px;
        min-height: 89px;
    }

    .blog-card-desc {
        font-size: 16px;
        padding-right: 10px;
        min-height: 182px
    }

    .blog-btn {
        width: 178px
    }

    .blog-card-btn {
        font-size: 14px
    }
}

@media (min-width:768px) and (max-width:991px) {
    .blog-card-title {
        font-size: 22px;
        line-height: 29px;
        min-height: 115px
    }

    .blog-card-desc {
        font-size: 16px;
        padding-right: 10px;
        min-height: 230px
    }

    .blog-btn {
        width: 140px
    }

    .blog-card-btn {
        font-size: 12px
    }
}

@media (min-width:576px) and (max-width:767px) {
    .blog-card-title {
        font-size: 22px;
        line-height: 26px;
    }

    .blog-card-desc {
        font-size: 16px;
        padding-right: 10px;
        line-height: 23px
    }

    .blog-btn {
        width: 140px
    }

    .blog-card-btn {
        font-size: 14px
    }
}

@media (min-width:481px) and (max-width:767px) {
    .blog-section-card {
        width: 46%
    }

    .blog-card-title {
        min-height: 97px;
    }

    .blog-card-desc {
        min-height: 208px;
    }
}

@media screen and (max-width:480px) {
    .blog-section-card {
        width: 100%
    }
}

@media screen and (max-width:575px) {
    .blog-section-card {
        overflow: hidden
    }

    .blog-section-card img {
        width: 100%;
        object-fit: contain
    }

    .blog-card-title {
        font-size: 20px;
        line-height: 24px;
        margin-top: .8rem
    }

    .blog-btn {
        width: 140px
    }

    .blog-card-desc {
        font-size: 15px;
        padding-right: 0;
        line-height: 23px;
        margin: .7rem 0
    }

    .blog-card-btn {
        font-size: 14px;
        margin-bottom: .5rem
    }
}