.blog-detail-section {
    margin: 80px 150px;
    display: flex;
    justify-content: space-between;
}

.blog-detail-container {
    width: 55%;
}

.blog-detail-container img {
    max-width: 100%;
}

.blog-detail-context {
    width: 35%;
    background: #EBF2FD;
    padding: 20px;
    height: 100%;
    position: sticky;
    top: 90px;
    left: 0;
}

.blog-detail-title {
    color: #0B4066;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 1rem 0;
}

.blog-detail-created {
    color: #696969;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1rem;
}

.blog-detail-header {
    border-bottom: 1px solid #EEE;
}

.blog-detail-short-desc {
    max-width: 772px;
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 1rem 0;

}

.blog-info p {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    max-width: 772px;
    margin: 1rem 0;

}

.blog-info h2 {
    color: #0B4066;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem ;
}

.blog-info h3 {
    color: #0B4066;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.blog-info ul {
    padding-left: 18px;
}

.blog-info ul li {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0.7rem;
}

.blog-info ul li::marker{
    color:  #FFB300;
}




.blog-detail-context h2 {
    color: #0B4066;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;
}

.blog-context h3 {
    color: #020D14;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}

.blog-context {
    text-decoration: none;
}


.blog-info a{
    text-decoration: none;
    color: #FFB300;
}


















@media (min-width:1200px) and (max-width:1400px) {
    .blog-detail-section {
        margin: 60px 100px;
    }
}


@media (min-width:992px) and (max-width:1199px) {
    .blog-detail-section {
        margin: 40px 50px;
    }
}

@media (min-width:601px) and (max-width:991px) {
    .blog-info h2 {
        font-size: 28px;
    }

    .blog-info h3 {
        font-size: 20px;
    }

    .blog-detail-title {
        font-size: 28px;
    }
}

@media screen and (max-width:991px) {
    .blog-detail-section {
        margin: 30px 20px;
    }

    .blog-detail-context {
        top: 75px;
    }

}

@media screen and (max-width:600px) {
    .blog-detail-section {
        flex-direction: column;
    }

    .blog-detail-title {
        font-size: 22px;
    }

    .blog-detail-container {
        width: 100%;
    }

    .blog-detail-context {
        width: 100%;
    }

    .blog-info h2 {
        font-size: 22px;
    }

    .blog-info h3 {
        font-size: 18px;
    }
}