.our-brands-section {
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
    margin: 0 150px;
    position: relative;
    margin-top: -118px;
    font-display: swap
}
.brands {
    border-bottom: 3px solid #ffb300
}
.partner-logos {
    display: flex;
    justify-content: space-between
}
.brands h4 {
    color: #0b4066;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    margin: 0
}
.brands-item {
    width: 25%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center
}
.brands-item img {
    width: 100%;
    height: 55px;
    object-fit: contain
}
.brands-item-border {
    border-right: 1px solid #eee
}
.brands-work {
    display: flex;
    justify-content: space-between
}
.brands-work-item {
    display: flex;
    width: 25%;
    padding: 40px 20px
}
.brands-work-item-content {
    display: flex;
    flex-direction: column
}
.brands-work-item-content h4 {
    color: #0b4066;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-display: swap;
    margin: 0
}
.brands-work-item-content p {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-display: swap;
    max-width: 219px;
    margin-top: 4px;
    margin-bottom: 0
}
.brands-work-item-logo {
    margin-right: 20px
}
.brands-work-item-logo img {
    width: 100%
}
.logo-hide {
    display: none
}
@media (min-width:1200px) and (max-width:1400px) {
    .our-brands-section {
        margin: 0 100px;
        margin-top: -118px
    }
    .brands-work-item {
        padding: 30px 20px
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .our-brands-section {
        margin: 0 50px;
        margin-top: -105px
    }
    .brands-item {
        padding: 24px
    }
    .brands-work-item {
        padding: 25px 20px
    }
    .brands-work-item-logo {
        margin-right: 15px
    }
}
@media (min-width:768px) and (max-width:991px) {
    .our-brands-section {
        margin: 0 20px;
        margin-top: -96px
    }
    .brands-item {
        padding: 20px
    }
    .brands-work-item-content h4 {
        font-size: 18px;
        margin-bottom: 0
    }
    .brands-work-item {
        padding: 20px
    }
    .brands h4 {
        font-size: 24px
    }
    .brands-work-item-logo {
        margin-right: 10px
    }
}

@media (min-width:576px) and (max-width:767px) {
    .our-brands-section {
        margin: 0 20px;
        margin-top: -95px
    }
    .brands-work-item-content h4 {
        font-size: 18px;
        margin-bottom: 0
    }
    .brands-item {
        padding: 20px 10px
    }
    .brands h4 {
        font-size: 20px
    }
    .brands-work-item {
        padding: 20px 10px
    }
    .brands-work-item-logo {
        margin-right: 10px
    }
    .brands-work-item-content p {
        margin-top: .5rem;
        font-size: 14px;
        margin-bottom: 0
    }
}

@media screen and (max-width:575px) {
    .our-brands-section {
        margin: 0 20px;
        margin-top: -76px
    }
    .brands h4 {
        font-size: 20px
    }
    .brands-item {
        padding: 10px 15px;
        width: 30%
    }
    .brands-work-item-content h4 {
        font-size: 18px;
        margin-bottom: 0
    }
    .brands-work {
        flex-wrap: wrap
    }
    .brands-work-item {
        width: 100%;
        padding: 12px 20px
    }
    .brands-work-item-logo {
        margin-right: 10px
    }
    .brands-work-item-content p {
        margin-top: .3rem;
        font-size: 15px;
        margin-bottom: 0
    }
    .logo-hide {
        display: block;
        width: 100%;
        overflow: hidden
    }
    .logo-hide .brands-item {
        width: 100%
    }
    .logo-hide .brands-item img {
        margin-bottom: 0
    }
    .logo-show {
        display: none
    }
}