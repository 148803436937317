.testimonials-section {
    margin: 80px 150px;
}

.testimonial-card {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.testimonial-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 25px;
}

.testimonial-desc {
    max-width: 476px;
    color: #696969;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    margin-bottom: 1rem;
}

.testimonial-author {
    color: #202020;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
}

.testimonial-position {
    color: #FFB300;
    font-size: 16px;
    font-style: normal;
    margin: 0;
    font-weight: 400;
    line-height: normal;
}



.service-section-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline
}

.service-section-btns {
    display: flex;
    align-items: center;
    color: #0b4066;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal
}

.service-section-btns img {
    cursor: pointer
}

.service-section-btns p {
    margin: 0 10px
}









/* Media Query */
@media (min-width:1200px) and (max-width:1400px) {
    .testimonials-section {
        margin: 60px 100px;
    }
    .testimonial-img img{
        height: 350px;
    }

    .testimonial-logo-1 img,
    .testimonial-logo-2 img{
        height: 30px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .testimonials-section {
        margin: 40px 50px;
    }

    .testimonial-img img{
        height: 350px;
    }

    .testimonial-logo-1 img,
    .testimonial-logo-2 img{
        height: 30px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .testimonial-desc {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 0.8rem;
    }

    .testimonial-author {
        font-size: 16px;
        margin-top: 0;
    }

    .testimonial-position {
        font-size: 13px;
    }

    .testimonial-logo-1 img {
        width: 40px;
    }

    .testimonial-logo-2 img {
        width: 40px;
    }

}

@media (min-width:576px) and (max-width:767px) {
    .testimonial-img {
        width: 50%;
    }

    .testimonial-content {
        width: 50%;
    }

    .testimonial-desc {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 0.8rem;
    }

    .testimonial-author {
        font-size: 16px;
        margin-top: 0;
    }

    .testimonial-position {
        font-size: 13px;
    }

    .testimonial-logo-1 img {
        width: 40px;
    }

    .testimonial-logo-2 img {
        width: 40px;
    }

}

@media screen and (max-width:575px) {
    .testimonial-card {
        margin-top: 10px;
    }

    .testimonial-img {
        width: 50%;
    }

    .testimonial-content {
        width: 50%;
        padding-right: 5px;
        padding-left: 10px;
    }

    .testimonial-desc {
        font-size: 14px;
        line-height: 16px;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }

    .testimonial-author {
        font-size: 13px;
        margin-top: 0;
    }

    .testimonial-position {
        font-size: 10px;
    }

    .testimonial-logo-1 img {
        width: 20px;
    }

    .testimonial-logo-2 img {
        width: 20px;
    }


}

@media screen and (max-width:991px) {
    .testimonials-section {
        margin: 30px 20px;
    }

    .testimonial-img img {
        width: 100%;
    }

}