.brand-detail-img{
    position: relative;
}
.brands-detail-banner{
    position: relative;
}
.brand-detail-img img{
    width: 100%;
}
.custom-text-about{
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.brand-detail-section {
    margin: 60px 150px
}
.brand-products {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 30px
}
.brand-logo {
    border-bottom: 1px solid #e7ecf0;
    padding-bottom: 20px
}
.brand-logo h2 {
    color: #0b4066;
    font-size: 34px;
    font-style: normal;
    font-weight: 600
}

.product-ct{
    color: #0b4066;
    font-size: 26px;
    font-style: normal;
    font-weight: 600
}

.brand-title {
    color: #ffb300;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 1rem 0
}

.brands-desc {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 1rem 0
}

.brand-desc-2 a{
    text-decoration: none;
    color: #ffb300;
}

.brand-desc-2 ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.brand-desc-2 ul li{
    list-style: none;
}

.brand-desc-2 h1 {
    color: #0b4066;
    font-size: 36px;
    margin-bottom: 1rem
}

.brand-desc-2 h2 {
    color: #0b4066;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-top: 1.5rem;
    margin-bottom: 1rem
}

.brand-desc-2 h3 {
    color: #0b4066;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-bottom: .3rem;
    margin-top: .7rem
}

.landing-page-section-bg{
    background: #ebf2fd;
    padding: 1px 0;
}

.landing-page-section-bg .partner-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landing-page-section-bg .partner-form{
    background-color: #FFF;
}

.landing-page-section-bg .partner-form .form-group{
   width: 50%;
}

.landing-page-section-bg .partner-form .form-group .form-control{
    background: none;
    border: 1px solid #DDD;
}

.w-100{
    width: 100% !important;
}



@media (min-width:1200px) and (max-width:1400px) {
    .brand-detail-section {
        margin: 60px 100px
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .brand-detail-section {
        margin: 40px 50px
    }

    .brand-products {
        margin-top: 30px
    }
}

@media (min-width:768px) and (max-width:991px) {
    .brand-products {
        margin-top: 30px;
        gap: 25px
    }
}

@media screen and (max-width:991px) {
    .brand-detail-section {
        margin: 30px 20px
    }

    .brand-products {
        margin-top: 30px
    }

    .brand-desc-2 h1 {
        font-size: 28px
    }

    .brand-desc-2 h2 {
        font-size: 26px
    }

    .brand-desc-2 h3 {
        font-size: 20px
    }
}

@media screen and (max-width:575px) {
    .brand-detail-img img{
        height: 170px;
    }
}